import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Subscribe from "./subscribe";

import {ReactComponent as LinkedinIcon} from "../images/icons/linkedin-icon.svg";
import {ReactComponent as FacebookIcon} from "../images/icons/facebook-icon.svg";

export default function Footer() {

    const { site } = useStaticQuery(graphql`
        query SiteSocialMediaQuery {
            site {
                siteMetadata {
                    facebookUrl
                    linkedinUrl
                }
            }
        }
    `);

    return (

        <footer className="text-gray-700 body-font">
            <div className="border-t border-gray-200">
                <div className="container px-5 py-8 flex flex-wrap mx-auto items-center">
                    <div className="flex md:flex-no-wrap flex-wrap justify-center md:justify-start">
                        <Subscribe />
                        <p className="text-gray-500 text-sm md:ml-6 md:mt-0 mt-2 sm:text-left text-center">
                            Subscribe to the mailing list
                            <br className="lg:block hidden" />
                            And Unsubscribe at anytime
                        </p>
                    </div>
                    <span className="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
                        <a href={site.siteMetadata.facebookUrl} className="text-gray-500">
                            <FacebookIcon className="w-5 h-5" />
                        </a>
                        <a href={site.siteMetadata.linkedinUrl} className="ml-3 text-gray-500">
                            <LinkedinIcon className="w-5 h-5" />
                        </a>
                    </span>
                </div>
            </div>
            <div className="bg-gray-200">
                <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                    <p className="text-gray-500 text-sm text-center sm:text-left">© {(new Date().getFullYear())} Aire Valley HR</p>
                    <span className="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">Made in Yorkshire</span>
                </div>
            </div>
        </footer>

    )

}