import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Contact from "../components/contact";
import FixedSocialLinks from "../components/FixedSocialLinks";


export default function Layout({ children }) {

    if (typeof window !== "undefined") {
        // eslint-disable-next-line global-require
        require("smooth-scroll")('a[href*="#"]')
    }

    return (
        <>
            <Header />
            {children}
            <Contact />
            <Footer />
            <FixedSocialLinks />
        </>
    )
}