import React from 'react';

import {ReactComponent as LinkedinIcon} from "../images/icons/linkedin-icon.svg";
import {ReactComponent as FacebookIcon} from "../images/icons/facebook-icon.svg";

export default function FixedSocialLinks() {

    return (
        <> 
            <a href="https://www.linkedin.com/company/aire-valley-hr/" className="rounded-full w-12 h-12 bg-gray-100 fixed bottom-0 right-0 flex items-center justify-center text-blue-700 mr-8 mb-24 shadow-sm border-gray-300 border"
                target="_blank" rel="noreferrer">
                    <LinkedinIcon className="w-5 h-5" />
            </a>

            <a href="https://www.facebook.com/Airevalley-HR-112636343849382"
                className="rounded-full w-12 h-12 bg-gray-100 fixed bottom-0 right-0 flex items-center justify-center text-blue-600 mr-8 mb-8 shadow-sm border-gray-300 border"
                target="_blank" rel="noreferrer">
                <FacebookIcon className="w-5 h-5" />
            </a>
        </>
    
    )

}


