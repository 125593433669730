import React from "react";

export default function Contact() {

    return (
        <section id="contact-form" className="text-gray-700 body-font relative">
            <div className="absolute inset-0 bg-gray-300">
                <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map"
                    scrolling="no"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d75920.89090395809!2d-1.8586347439477324!3d53.8637162595588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1595648950698!5m2!1sen!2suk"
                    style={{filter: 'grayscale(1) contrast(1.2) opacity(0.4)'}}></iframe>
            </div>
            <div className="container px-5 py-24 mx-auto flex">
                {/* Disable Send Button When Form Unfilled */}
                {/* Add Form Validation */}
                {/* Replace Form with a Sent Tick After Successfull Send */}
                <form name="contact" method="POST" data-netlify="true" className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10">
                    <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
                    <p className="leading-relaxed mb-5 text-gray-600">Please provide as much information as possible</p>
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                        className="bg-white rounded border border-gray-400 focus:outline-none focus:border-blue-500 text-base px-4 py-2 mb-4"
                        placeholder="Name" type="text" name="name" required>
                    </input>
                    <input
                        className="bg-white rounded border border-gray-400 focus:outline-none focus:border-blue-500 text-base px-4 py-2 mb-4"
                        placeholder="Your Contact Email" type="email" name="email" required>
                    </input>
                    <textarea
                        className="bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-blue-500 text-base px-4 py-2 mb-4 resize-none"
                        placeholder="Message" name="message" required>
                    </textarea>
                    {/* <div data-netlify-recaptcha="true" className="py-2 px-6 mb-4"></div> */}
                    <button type="submit" className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send</button>
                    <p className="text-xs text-gray-500 mt-3 text-center">Your information will only be held for the duration of the exchange.</p>
                </form>
            </div>
        </section>
    )

}