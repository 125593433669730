import React from "react"
import { graphql, useStaticQuery } from "gatsby";

import {ReactComponent as RightArrowIcon} from "../images/icons/arrow-right.svg";
import {ReactComponent as AireValleyHrLogoIcon} from "../images/icons/aire-valley-logo-icon.svg";

export default function Header() {

    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <header className="text-gray-700 body-font border-b border-gray-200">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href="/">
                    <AireValleyHrLogoIcon className="w-10 h-10 text-white p-2 bg-blue-500 rounded" />
                    <span className="ml-3 text-xl">{site.siteMetadata.title}</span>
                </a>
                <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    <a href="#services" className="mr-5 hover:text-gray-900">Services</a>
                    {/* <a href="https://blog.airevalleyhr.co.uk" className="mr-5 hover:text-gray-900">Blog</a> */}
                    <a href="#testimonials" className="mr-5 hover:text-gray-900">Testimonials</a>
                </nav>
                <a href="#contact-form" className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0">Contact
                    <RightArrowIcon className="w-4 h-4 ml-1"/>
                </a>
            </div>
        </header>
    )
}