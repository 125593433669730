import React, { Component } from "react";

import addToMailchimp from 'gatsby-plugin-mailchimp'


export default class Subscribe extends Component {

    state = {
        email: "",  
        msg: "",
        disabled: false,
    }

    _handleSubmit = async (e) => {
        e.preventDefault();

        const { email } = this.state

        if (!email) {
            return false
        }

        this.setState({ disabled: true })

        try {
            const response = await addToMailchimp(email)
            const { msg } = response
      
            this.setState({
              msg,
            })
          } catch (error) {
            this.setState({
              msg: "There was an error while adding your email to our list, please reload and try again.",
            })
          }
      
          // enable submit button
          this.setState({ disabled: false })

    }

    handleChange = event => {
        this.setState({ email: event.target.value })
    }

    render() {

        const { disabled } = this.state

        return (
            <form onSubmit={this._handleSubmit}>
                <input className="sm:w-64 w-40 bg-gray-100 rounded sm:mr-4 mr-2 border border-gray-400 focus:outline-none focus:border-indigo-500 text-base py-2 px-4"
                    placeholder="your@email.co.uk" type="email" value={this.state.email} onChange={this.handleChange} disabled={disabled} />
                <button type="submit" className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Subscribe</button>
            </form>
        )

    }
    
}
